import { useLogto } from "@logto/react";
import { useQuery } from "@tanstack/react-query";
import config from "configs/app";
import useApiQuery from "lib/api/useApiQuery";
import * as cookies from "lib/cookies";
import Router from "next/router";
import { route } from "nextjs-routes";
import { useEffect, useState } from "react";

export default function useFetchProfileInfo() {
  const { isAuthenticated, getAccessToken, isLoading, clearAllTokens } =
    useLogto();

  const [isLogged, setIsLogged] = useState<boolean | "loading">("loading");

  useEffect(() => {
    if (isLoading) {
      return;
    }

    setIsLogged(isAuthenticated);
  }, [isLoading]);

  const { data: accessToken, isFetched: accessTokenIsFetched } = useQuery({
    queryKey: ["access_token"],
    queryFn: async () => {
      return await getAccessToken(config.app.authWorkspace.resources[0]).catch(
        (error) => {
          clearAllTokens();
          throw error;
        },
      );
    },
    enabled: isLogged === true,
    retry: false,
  });

  const { isFetched: isWorkspaceTokenIsFetched } = useApiQuery(
    "issue_workspace_token",
    {
      fetchParams: {
        method: "GET",
      },
      queryParams: {
        access_token: accessToken!,
      },
      queryOptions: {
        staleTime: Infinity,
        enabled: Boolean(isLogged === true && accessToken),
      },
    },
  );

  const { isFetched: myQuotaIsFetched } = useApiQuery("my_quota", {
    queryOptions: {
      staleTime: Infinity,
      enabled: Boolean(isLogged === true && accessToken),
    },
  });

  /**
   * @description auto create quota
   */

  const response = useApiQuery("workspace_userinfo", {
    queryOptions: {
      refetchOnMount: false,
      retryOnMount: false,
      enabled: isLogged === true,
    },
  });

  useEffect(() => {
    if (!response.error) return;
    console.log(response.error);
    if (
      response.error?.status === 401 &&
      (response.error?.payload as any)?.message === "JWT:USER::SUSPENDED"
    ) {
      clearAllTokens().then(() => {
        cookies.deleteKey(cookies.NAMES.API_TOKEN);

        Router.push(
          route({
            pathname: "/unauthorization",
            query: { message: (response.error?.payload as any)?.message },
          }),
        );
      });
    }
  }, [response.error]);

  return {
    ...response,
    isLogged,
    accessToken,
    userInfoIsFetched: response.isFetched,
    isFetched:
      accessTokenIsFetched &&
      isLogged &&
      isWorkspaceTokenIsFetched &&
      myQuotaIsFetched &&
      response.isFetched,
  };
}
