import { Box } from "@chakra-ui/react";
import HeaderProvider from "lib/contexts/HeaderContext";
import { useRouter } from "next/router";
import AppErrorBoundary from "ui/shared/AppError/AppErrorBoundary";
import Header from "ui/snippets/header/Header";
import * as Layout from "./components";
import type { Props } from "./types";

const LayoutHeader = ({ children }: Props) => {
  const router = useRouter();

  return (
    <Layout.Container>
      <HeaderProvider>
        <Layout.TopBar homePage={router.pathname === "/"} />
        <Box width="full" height="1px" backgroundColor="neutral.light.4"></Box>
        <Header homePage={router.pathname === "/"} />
      </HeaderProvider>

      <Layout.Content>
        <AppErrorBoundary>{children}</AppErrorBoundary>
      </Layout.Content>
      <Layout.Footer />
    </Layout.Container>
  );
};

export default LayoutHeader;
