import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  useDisclosure,
} from "@chakra-ui/react";
import React, { Fragment, useEffect } from "react";
// import dynamic from "next/dynamic";

import useNavItems, { isGroupItem } from "lib/hooks/useNavItems";
import IconSvg from "ui/shared/IconSvg";
import NavLink from "ui/snippets/navigation/NavLink";
import NetworkLogo from "ui/snippets/networkMenu/NetworkLogo";

import config from "configs/app";
import { FULL_DVH } from "lib/consts";
import { useHeaderContext } from "lib/contexts/HeaderContext";
import useIsMobile from "lib/hooks/useIsMobile";
import { useRouter } from "next/router";
import ActionToggle from "ui/shared/button/ActionToggle";
import DetailsInfoItemDivider from "ui/shared/DetailsInfoItemDivider";
import { hideKeyboard, smoothScroll } from "ui/utils/dom";

import ProfileMenu from "ui/profile/ProfileMenu";
import Divider from "ui/shared/Divider";
import NavDesktop from "./NavDesktop";
import NavMobile from "./NavMobile";

const Header = ({ homePage }: { homePage?: boolean }) => {
  const { mainNavItems } = useNavItems();
  const { searchElement } = useHeaderContext();
  const { isOpen, onToggle, onClose } = useDisclosure();
  const containerRef = React.useRef<HTMLDivElement>(null);
  const router = useRouter();
  const handleToggleCollapseMenu = () => {
    onToggle();
  };

  const isMobile = useIsMobile();
  useEffect(() => {
    if (isMobile) {
      if (isOpen) {
        hideKeyboard();
        smoothScroll(containerRef.current!)?.then(() => {
          document.documentElement.style.top = "-53px";
          document.documentElement.style.position = "fixed";
        });
      }
    }
  }, [isMobile, isOpen]);

  useEffect(() => {
    if (isOpen) {
      document.documentElement.style.transition = "none";
      document.documentElement.style.overflowY = "hidden";
    } else {
      document.documentElement.style.transition = "top 0.2s ease-in-out";
      document.documentElement.style.overflowY = "auto";
      document.documentElement.style.position = "relative";
      document.documentElement.style.top = "0px";
    }
  }, [isOpen]);

  return (
    <Box
      ref={containerRef}
      zIndex={999}
      as="header"
      bgColor="neutral.light.1"
      borderBottomWidth="1px"
      transitionProperty="box-shadow"
      transitionDuration="slow"
      width="full"
      id="header"
      gap={2}
      paddingY="0.75rem"
      position="relative"
      alignItems="stretch"
      boxShadow="md"
    >
      <Flex
        alignItems="center"
        w={{ base: "100%", xl: "1440px" }}
        px={{ base: 4, lg: 5, "2lg": 8, xl: 10 }}
        mx="auto"
        justifyContent="space-between"
      >
        <NetworkLogo />
        <Flex alignItems="center" float="right" gap={{ base: 2, lg: 5, xl: 8 }}>
          <Flex
            order={1}
            alignItems="center"
            gap={{ base: 2, lg: 5, xl: 8 }}
            float="right"
          >
            {/* <Wraper> */}
            <Flex
              left={0}
              top="61px"
              zIndex={1001}
              backgroundColor="white"
              height={{
                base: isOpen ? `calc(${FULL_DVH} - 61px)` : "0px",
                lg: "unset",
              }}
              gap={{ base: 0, lg: 5, xl: 8 }}
              transition="all 0.3s ease-in-out"
              paddingX={{ base: 4, lg: "unset" }}
              onClick={(e) => e.stopPropagation()}
              width={{ base: "full", lg: "unset" }}
              boxShadow={{ base: "md", lg: "none" }}
              overflowY={{ base: "auto", lg: "unset" }}
              position={{ base: "absolute", lg: "static" }}
              flexDirection={{ base: "column", lg: "row" }}
              alignItems={{ base: "stretch", lg: "center" }}
              borderBottomWidth={{ base: "1px", lg: "0px" }}
              sx={{ transform: { lg: "translate(0, 0) !important" } }}
              justifyContent={{ base: "flex-start", lg: "flex-end" }}
              maxHeight={{ lg: "2.25rem !important" }}
            >
              {mainNavItems.map((item, index) => {
                return (
                  <Box
                    _first={{ mt: { base: "2rem", lg: "unset" } }}
                    _last={{ mb: { base: "2rem", lg: "unset" } }}
                    key={index}
                  >
                    {isGroupItem(item) && (
                      <>
                        {isMobile && (
                          <Accordion allowToggle>
                            <AccordionItem
                              flexDirection="column"
                              borderTopWidth={0}
                              borderBottomWidth={0}
                              _last={{ borderBottomWidth: 0 }}
                            >
                              {({ isExpanded }) => (
                                <>
                                  <AccordionButton
                                    cursor="pointer"
                                    _hover={{
                                      lg: { color: "primary.light.4" },
                                    }}
                                    color={
                                      item.isActive
                                        ? "primary.light.4"
                                        : "neutral.light.7"
                                    }
                                    fontWeight={400}
                                    lineHeight="1.5rem"
                                    fontSize="1rem"
                                    alignItems="center"
                                    display="flex"
                                    gap={1}
                                    borderBottomWidth={0}
                                    paddingX={{ base: 0, lg: "unset" }}
                                    paddingY={{ base: 3, lg: "unset" }}
                                  >
                                    <span>{item.text}</span>
                                    <IconSvg
                                      name="east-solid"
                                      boxSize="16px"
                                      position="relative"
                                      top="2px"
                                      transition="transform 0.2s linear"
                                      transform={
                                        isExpanded
                                          ? "rotate(180deg)"
                                          : undefined
                                      }
                                      color={
                                        isExpanded
                                          ? "primary.light.4"
                                          : "neutral.light.6"
                                      }
                                    />
                                  </AccordionButton>
                                  <AccordionPanel
                                    borderBottomWidth={0}
                                    padding={0}
                                  >
                                    {[
                                      item.subItemsTop,
                                      item.subItems,
                                      ...(item.othersSubItems || []),
                                    ].map((subItems, index) => {
                                      if (!subItems?.length) return <></>;
                                      return (
                                        <Fragment key={index}>
                                          {subItems?.map((subItem, index) => (
                                            <NavMobile
                                              key={`sub-item-mobile-${index}`}
                                              item={subItem}
                                              onClick={handleToggleCollapseMenu}
                                            />
                                          ))}
                                        </Fragment>
                                      );
                                    })}
                                  </AccordionPanel>
                                </>
                              )}
                            </AccordionItem>
                          </Accordion>
                        )}

                        {!isMobile && (
                          <Menu>
                            <MenuButton
                              cursor="pointer"
                              _hover={{ color: "primary.light.4" }}
                              color={
                                item.isActive
                                  ? "primary.light.4"
                                  : "neutral.light.7"
                              }
                              fontWeight={400}
                              lineHeight="1.5rem"
                              fontSize="1rem"
                              alignItems="center"
                              display="flex"
                            >
                              <Flex alignItems="center" gap={1}>
                                <span>{item.text}</span>
                                <IconSvg
                                  name="east-solid"
                                  boxSize="16px"
                                  position="relative"
                                  color={
                                    item.isActive
                                      ? "primary.light.4"
                                      : "neutral.light.7"
                                  }
                                />
                              </Flex>
                            </MenuButton>
                            <MenuList>
                              {[
                                item.subItemsTop,
                                item.subItems,
                                ...(item.othersSubItems || []),
                              ].map((subItems, index) => {
                                if (!subItems?.length) return <></>;
                                return (
                                  <Fragment key={index}>
                                    {index !== 0 && (
                                      <Divider height="1px" width="full" />
                                    )}
                                    {subItems?.map((subItem, index) => (
                                      <NavDesktop
                                        key={`sub-item-desktop-${index}`}
                                        item={subItem}
                                      />
                                    ))}
                                  </Fragment>
                                );
                              })}
                            </MenuList>
                          </Menu>
                        )}
                      </>
                    )}

                    {!isGroupItem(item) && (
                      <NavLink
                        item={item}
                        isCollapsed={false}
                        isActive={(item as any)?.isActive}
                        color={(item as any)?.isActive && "primary.light.4"}
                        cursor="pointer"
                        _hover={{ color: "primary.light.4" }}
                        fontWeight={400}
                        lineHeight="1.5rem"
                        fontSize="1rem"
                        alignItems="center"
                        display="flex"
                        onClick={() => {
                          if (isMobile) {
                            handleToggleCollapseMenu();
                          }
                        }}
                      />
                    )}

                    {index !== mainNavItems.length - 1 && (
                      <DetailsInfoItemDivider
                        height="1px"
                        display={{ lg: "none" }}
                        mt={0}
                        mb={0}
                        w="full"
                      />
                    )}
                  </Box>
                );
              })}
            </Flex>
          </Flex>
          {isMobile && !homePage && searchElement}

          {config.features.account.isEnabled &&
            !(
              isMobile &&
              (router.pathname.startsWith("/auth") ||
                router.pathname.startsWith("/account"))
            ) && (
              <Box position="relative" order={3}>
                <ProfileMenu onClick={onClose} />
              </Box>
            )}
          {!(isMobile && router.pathname.startsWith("/auth")) && (
            <ActionToggle
              display={{ lg: "none" }}
              onClick={handleToggleCollapseMenu}
              isOpen={isOpen}
            ></ActionToggle>
          )}
        </Flex>
      </Flex>
    </Box>
  );
};

export default React.memo(Header);
