import Footer from "ui/snippets/footer/Footer";
import TopBar from "ui/snippets/topBar/TopBar";

import Container from "./Container";
import Content from "./Content";
import MainArea from "./MainArea";
import { default as MainColumn } from "./MainColumn";

export { Container, Content, Footer, MainArea, MainColumn, TopBar };

// Container
//    TopBar
//    MainArea
//       MainColumn
//          Content
//    Footer
