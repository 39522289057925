import type {
  As,
  FlexProps,
  IconProps,
  TagProps,
  TooltipProps,
} from "@chakra-ui/react";
import { chakra, Flex, forwardRef, Skeleton, Text } from "@chakra-ui/react";
import React from "react";

import type { Props as CopyToClipboardProps } from "ui/shared/CopyToClipboard";
import CopyToClipboard from "ui/shared/CopyToClipboard";
import type { IconName, IconSvgProps } from "ui/shared/IconSvg";
import IconSvg from "ui/shared/IconSvg";
import LinkExternal from "ui/shared/LinkExternal";
import LinkInternal from "ui/shared/LinkInternal";
import HashStringShorten from "ui/shared/truncate/HashStringShorten";
import HashStringShortenDynamic from "ui/shared/truncate/HashStringShortenDynamic";

import { getAssociationTokenData } from "lib/association";
import { TokenInfo } from "types/api/token";
import { Association } from "types/api/transaction";
import Tag from "ui/shared/chakra/Tag";
import TruncatedTextTooltip from "ui/shared/truncate/TruncatedTextTooltip";
import { getIconProps, type IconSize } from "./utils";

export type Truncation = "constant" | "dynamic" | "tail" | "none";

export interface EntityBaseProps {
  className?: string;
  href?: string;
  iconSize?: IconSize | string;
  isExternal?: boolean;
  isLoading?: boolean;
  isValidator?: boolean;
  noCopy?: boolean;
  isNa?: boolean;
  noIcon?: boolean;
  linkColor?: string;
  noLink?: boolean;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  query?: Record<string, string>;
  length?: number;
  tailLength?: number;
  headLength?: number;
  isFullAddress?: boolean;
  target?: React.HTMLAttributeAnchorTarget;
  truncation?: Truncation;
  justify?: "center" | "between";
  showAssociation?: boolean;
}

export interface ContainerBaseProps
  extends Pick<EntityBaseProps, "className">,
    FlexProps {
  children: React.ReactNode;
}

const Container = forwardRef<ContainerBaseProps, "div">(
  ({ children, className, ...props }, ref) => (
    <Flex
      ref={ref}
      className={className}
      alignItems="center"
      minWidth={0}
      {...props}
    >
      {children}
    </Flex>
  ),
);

export interface LinkBaseProps
  extends Pick<
    EntityBaseProps,
    | "className"
    | "onClick"
    | "isLoading"
    | "isExternal"
    | "href"
    | "noLink"
    | "query"
    | "isValidator"
  > {
  isNa?: boolean;
  children: React.ReactNode;
  target?: React.HTMLAttributeAnchorTarget;
  parentLink?: string;
}

const Link = chakra(
  forwardRef<LinkBaseProps, "a">(
    (
      {
        className,
        isLoading,
        children,
        isExternal,
        href,
        noLink,
        isNa,
        target = "_self",
        onClick,
      },
      ref,
    ) => {
      const styles = {
        display: "block",
        alignItems: "center",
        minWidth: 0,
      };

      if (noLink || isNa) {
        return (
          <Skeleton
            isLoaded={!isLoading}
            {...styles}
            className={className}
            ref={ref}
          >
            {children}
          </Skeleton>
        );
      }

      const Component = isExternal ? LinkExternal : LinkInternal;

      return (
        <Component
          ref={ref}
          {...styles}
          href={href!}
          isLoading={isLoading}
          onClick={onClick}
          className={className}
          target={target}
        >
          {children}
        </Component>
      );
    },
  ),
);

export type IconBaseProps = {
  name: IconName;
  color?: IconProps["color"];
  borderRadius?: IconProps["borderRadius"];
} & Pick<EntityBaseProps, "isLoading" | "iconSize" | "noIcon"> &
  Partial<IconSvgProps>;

const Icon = ({
  isLoading,
  iconSize,
  noIcon,
  name,
  color,
  borderRadius,
  ...props
}: IconBaseProps) => {
  if (noIcon) {
    return null;
  }

  const styles = getIconProps(iconSize || "md");
  return (
    <IconSvg
      name={name}
      boxSize={styles.boxSize}
      isLoading={isLoading}
      minW={0}
      flexShrink={0}
      borderRadius={borderRadius ?? "sm"}
      color={color ?? "neutral.light.7"}
      {...props}
    />
  );
};

export interface ContentBaseProps
  extends Pick<
    EntityBaseProps,
    | "className"
    | "isLoading"
    | "truncation"
    | "tailLength"
    | "headLength"
    | "noLink"
    | "isValidator"
    | "linkColor"
  > {
  asProp?: As;
  text: string;
  color?: string;
  noTooltip?: boolean;
  tooltipProps?: Partial<TooltipProps>;
  fontWeight?: string | number;
  justify?: "center" | "between";
  entityRef?: React.MutableRefObject<HTMLElement | null>;
  contentBoxRef?: React.MutableRefObject<HTMLElement | null>;
}

const Content = chakra(
  ({
    text,
    asProp,
    justify,
    className,
    isLoading,
    linkColor,
    entityRef,
    noTooltip,
    fontWeight,
    tailLength,
    headLength,
    tooltipProps,
    contentBoxRef,
    truncation = "dynamic",
  }: ContentBaseProps) => {
    const children = (() => {
      switch (truncation) {
        case "constant":
          return (
            <HashStringShorten
              tailLength={tailLength}
              headLength={headLength}
              fontWeight={fontWeight}
              hash={text}
              as={asProp}
              color={linkColor ? `${linkColor} !important` : ""}
              isTooltipDisabled
            />
          );
        case "dynamic":
          return (
            <HashStringShortenDynamic
              fontWeight={fontWeight}
              hash={text}
              as={asProp}
              tailLength={tailLength}
              headLength={headLength}
              justify={justify}
              color={linkColor ? `${linkColor} !important` : ""}
              entityRef={entityRef}
              contentBoxRef={contentBoxRef}
              isTooltipDisabled
            />
          );
        case "tail":
          return (
            <Text
              isTruncated={!noTooltip}
              className={className}
              color={linkColor ? `${linkColor} !important` : ""}
            >
              {text}
            </Text>
          );
        case "none":
          return <chakra.span as={asProp}>{text}</chakra.span>;
      }
    })();

    return (
      <TruncatedTextTooltip
        defaultIsTruncated={truncation === "constant"}
        label={text}
        isDisabled={noTooltip}
        {...tooltipProps}
      >
        <Skeleton
          className={className}
          isLoaded={!isLoading}
          overflow="hidden"
          whiteSpace="nowrap"
          width="full"
        >
          {children}
        </Skeleton>
      </TruncatedTextTooltip>
    );
  },
);

export type CopyBaseProps = CopyToClipboardProps &
  Pick<EntityBaseProps, "noCopy" | "isNa">;

const Copy = (props: CopyBaseProps) => {
  if (props.noCopy || props.isNa) {
    return null;
  }

  return <CopyToClipboard {...props} />;
};

export type AssociationProps = {
  token: Partial<TokenInfo>;
  address: string | undefined | null;
  association: Association | undefined | null;
} & Pick<EntityBaseProps, "isLoading" | "showAssociation"> &
  TagProps;

const AssociationTag = ({
  address,
  association,
  showAssociation,
  ...props
}: AssociationProps) => {
  if (!showAssociation || !association || !address) return <></>;
  const associationData = getAssociationTokenData(props.token as TokenInfo);
  if (!associationData) return <></>;
  return (
    <Tag colorScheme="orange" {...props}>
      {associationData.self}
    </Tag>
  );
};

export { AssociationTag, Container, Content, Copy, Icon, Link };
