import type { ChakraProps, ImageProps } from "@chakra-ui/react";
import { Image, Skeleton, Tooltip, chakra } from "@chakra-ui/react";
import _omit from "lodash/omit";

import type { TokenInfo } from "types/api/token";

import { route } from "nextjs-routes";

import * as EntityBase from "ui/shared/entities/base/components";
import TokenLogoPlaceholder from "ui/shared/TokenLogoPlaceholder";

import useApiQuery from "lib/api/useApiQuery";
import { IIBCTokenType } from "types/api/ibcRelayer";
import IconSvg, { IconSvgProps } from "ui/shared/IconSvg";

type LinkProps = EntityBase.LinkBaseProps & Pick<EntityProps, "token">;

const Link = chakra((props: LinkProps) => {
  const defaultHref = route({
    pathname: "/token/[...slug]",

    query: {
      ...props.query,
      slug: [props.token.address],
    },
  });

  return (
    <EntityBase.Link
      {...props}
      noLink={
        !props.token.type ||
        !props.token.address ||
        props.token.address === "usei"
          ? true
          : undefined
      }
      href={props.href ?? defaultHref}
    >
      {props.children}
    </EntityBase.Link>
  );
});

type ConfirmIconPosition = "icon" | "name" | "symbol";

type ConfirmIconProps = {
  confirmIconPosition: ConfirmIconPosition;
  isLoading?: boolean;
  token: Partial<EntityProps["token"]> & { address: string };
} & Partial<IconSvgProps>;

const ConfirmIcon = ({
  isLoading,
  token,
  confirmIconPosition,
  ...props
}: ConfirmIconProps) => {
  const { data, isLoading: isGlobalTagsLoading } =
    useApiQuery("global_public_tags");

  const isConfirm = Boolean(
    data?.some(
      (publicTag) =>
        publicTag.address_hash.toLocaleLowerCase() ===
        token?.address.toLocaleLowerCase(),
    ),
  );

  // const isConfirm = true;

  if (!isConfirm) return <></>;
  return (
    <Tooltip label="Reputation OK: evaluated to be a token of public interest by Seitrace">
      <IconSvg
        isLoading={isLoading || isGlobalTagsLoading}
        name="verified_v2"
        boxSize={5}
        color="secondary.04"
        position={(confirmIconPosition === "icon" && "absolute") || "static"}
        borderRadius="full"
        backgroundColor="neutral.light.1"
        overflow="hidden"
        display="flex"
        alignItems="center"
        justifyContent="center"
        bottom="-0.12rem"
        right={0}
        transform={
          (confirmIconPosition === "icon" && "translate(50%, 0)") || undefined
        }
        {...props}
      />
    </Tooltip>
  );
};

type IconProps = Pick<
  EntityProps,
  "token" | "isLoading" | "iconSize" | "noIcon" | "className"
> & {
  marginRight?: ChakraProps["marginRight"];
  boxSize?: ChakraProps["boxSize"];
  confirmIconProps?: Partial<IconSvgProps>;
  confirmIconPosition?: ConfirmIconPosition | undefined;
} & Partial<ImageProps>;

const Icon = ({
  noIcon,
  token,
  isLoading,
  confirmIconPosition,
  confirmIconProps,
  ...props
}: IconProps) => {
  if (noIcon) {
    return <></>;
  }

  if (isLoading)
    return (
      <Skeleton
        boxSize={4}
        borderRadius="full"
        position="relative"
        flexShrink={0}
      ></Skeleton>
    );

  return (
    <>
      <Image
        boxSize={4}
        borderRadius="full"
        src={
          (token.base_denom === "usei" && "/images/sei.svg") ||
          token?.icon_url ||
          undefined
        }
        alt={`${token?.name || "token"} logo`}
        fallback={<TokenLogoPlaceholder type={token?.type} {...props} />}
        {...props}
      />
      {confirmIconPosition === "icon" && (
        <ConfirmIcon
          confirmIconPosition="icon"
          token={token}
          isLoading={isLoading}
          {...confirmIconProps}
        ></ConfirmIcon>
      )}
    </>
  );
};

type ContentProps = Omit<
  EntityBase.ContentBaseProps,
  "text" | "contentBoxRef"
> &
  Pick<EntityProps, "token" | "jointSymbol" | "onlySymbol"> & {
    showHash?: boolean;
  };

const Content = chakra(
  ({ showHash, noTooltip, tooltipProps, ...props }: ContentProps) => {
    const nameString =
      (showHash && props.token?.address) ||
      [
        !props.onlySymbol &&
          (props.token?.name ||
            props.token?.base_denom ||
            props.token?.token_denom ||
            "Unnamed Token"),
        props.onlySymbol && (props.token?.symbol ?? ""),
        props.token?.symbol &&
          props.jointSymbol &&
          !props.onlySymbol &&
          !props.token?.name?.startsWith("factory/") &&
          !props.token?.base_denom?.startsWith("factory/") &&
          !props.token?.token_denom?.startsWith("factory/") &&
          `(${props.token?.symbol})`,
      ]
        .filter(Boolean)
        .join(" ");

    return (
      <Tooltip
        label={
          props.token.address ||
          props.token.name ||
          props.token.base_denom ||
          props.token.token_denom ||
          props.token.symbol
        }
        isDisabled={noTooltip}
        noTooltip={noTooltip}
        {...tooltipProps}
      >
        <Skeleton
          isLoaded={!props.isLoading}
          overflow="hidden"
          height="fit-content"
          isTruncated
        >
          {/* <Text as="span" color="inherit" isTruncated> */}
          {nameString}
          {/* </Text> */}
        </Skeleton>
      </Tooltip>
    );
  },
);

type SymbolProps = Pick<
  EntityProps,
  "token" | "isLoading" | "noSymbol" | "jointSymbol" | "onlySymbol"
>;

const Symbol = (props: SymbolProps) => {
  const symbol = props.token?.symbol;

  if (!symbol || props.noSymbol || props.jointSymbol || props.onlySymbol) {
    return null;
  }

  return (
    <Skeleton
      isLoaded={!props.isLoading}
      display="inline-flex"
      alignItems="center"
      // maxW="20%"
      // flexShrink={0}
      // ml={2}
      overflow="hidden"
      // color="neutral.light.8"
    >
      <div>(</div>
      <Tooltip label={symbol}>
        <chakra.span isTruncated height="fit-content">
          {symbol}
        </chakra.span>
      </Tooltip>
      <div>)</div>
    </Skeleton>
  );
};

type CopyProps = Omit<EntityBase.CopyBaseProps, "text"> &
  Pick<EntityProps, "token">;

const Copy = (props: CopyProps) => {
  return <EntityBase.Copy {...props} text={props.token?.address} />;
};

const Container = EntityBase.Container;

export interface EntityProps extends EntityBase.EntityBaseProps {
  token: Pick<
    TokenInfo,
    "address" | "icon_url" | "name" | "symbol" | "association"
  > &
    Partial<
      Omit<
        TokenInfo,
        "address" | "icon_url" | "name" | "symbol" | "association" | "type"
      >
    > & { type: TokenInfo["type"] | IIBCTokenType };
  noSymbol?: boolean;
  jointSymbol?: boolean;
  onlySymbol?: boolean;
  iconProps?: Omit<
    Partial<IconProps>,
    "confirmIconProps" | "token" | "confirmIconPosition"
  >;
  showHash?: boolean;
  linkProps?: Partial<LinkProps>;
  symbolProps?: Partial<SymbolProps>;
  confirmIconProps?: Partial<IconSvgProps>;
  confirmIconPosition?: ConfirmIconPosition;
  noTooltip?: EntityBase.ContentBaseProps["noTooltip"];
  tooltipProps?: ContentProps["tooltipProps"];
}

const TokenEntity = ({
  iconProps,
  linkProps,
  symbolProps,
  noIcon,
  confirmIconProps,
  confirmIconPosition,
  showAssociation,
  ...props
}: EntityProps) => {
  const linkPartsProps = _omit(props, ["className"]);
  const partsProps = _omit(props, ["className", "onClick"]);

  return (
    <Container className={props.className} w="100%" gap={1}>
      <Icon
        token={props.token}
        isLoading={props.isLoading}
        noIcon={noIcon}
        confirmIconProps={confirmIconProps}
        confirmIconPosition={confirmIconPosition}
        {...iconProps}
      />
      <Link {...linkPartsProps} {...linkProps}>
        <Content {...partsProps} />
      </Link>
      {confirmIconPosition === "name" && (
        <ConfirmIcon
          confirmIconPosition="name"
          token={props.token}
          isLoading={props.isLoading}
          {...confirmIconProps}
        ></ConfirmIcon>
      )}
      <Symbol {...partsProps} {...symbolProps} />
      {confirmIconPosition === "symbol" && (
        <ConfirmIcon
          confirmIconPosition="symbol"
          token={props.token}
          isLoading={props.isLoading}
          {...confirmIconProps}
        ></ConfirmIcon>
      )}
      <EntityBase.AssociationTag
        token={props.token as TokenInfo}
        isLoading={props.isLoading}
        address={props.token?.address}
        association={props.token?.association}
        showAssociation={showAssociation}
      ></EntityBase.AssociationTag>
      <Copy {...partsProps} />
    </Container>
  );
};

export default chakra(TokenEntity);

export { Container, Content, Copy, Icon, Link };
