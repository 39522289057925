import { Box, Center, Text } from "@chakra-ui/react";
import ProfileSignOutButton from "ui/profile/components/ProfileSignOutGroup";
import AppErrorIcon from "../AppErrorIcon";
import AppErrorTitle from "../AppErrorTitle";

type Props = {
  //
};

const AppErrorUnauthorization = ({}: Props) => {
  return (
    <Box
      position="absolute"
      left="50%"
      top="50%"
      transform="translate(-50%, -50%)"
    >
      <AppErrorIcon statusCode={403} />
      <Center
        flexDirection="column"
        position="absolute"
        left="50%"
        top="50%"
        width="100vw"
        paddingX={4}
        transform="translate(-50%, -50%)"
        textAlign="center"
      >
        <AppErrorTitle title="Unauthorization" />
        <Text mt={3} textStyle="1" color="neutral.light.8">
          Your account is suspended. You can't log in.
        </Text>
        <ProfileSignOutButton></ProfileSignOutButton>
      </Center>
    </Box>
  );
};

export default AppErrorUnauthorization;
