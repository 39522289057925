import { theme } from "@chakra-ui/react";

export const BODY_TYPEFACE = "Inter";
export const HEADING_TYPEFACE = "Poppins";

const typography = {
  fonts: {
    heading: `${HEADING_TYPEFACE}, ${theme.fonts.heading}`,
    body: `${BODY_TYPEFACE}, ${theme.fonts.body}`,
  },
  textStyles: {
    h2: {
      fontSize: ["32px"],
      fontWeight: "500",
      lineHeight: "40px",
      fontFamily: "heading",
    },
    h3: {
      fontSize: "24px",
      fontWeight: "500",
      lineHeight: "32px",
      fontFamily: "heading",
    },
    tr: {
      fontSize: "0.875rem",
      fontWeight: 500,
      lineHeight: "1.25rem",
    },
    td: {
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: "1.25rem",
      color: "neutral.light.7",
    },
    title: {
      fontSize: "1.25rem",
      fontWeight: "600",
      lineHeight: "1.75rem",
      letterSpacing: "-0.0125rem",
    },
    400: {
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: "1.5rem",
    },
    500: {
      fontSize: "1rem",
      fontWeight: 500,
      lineHeight: "1.5rem",
    },
    600: {
      fontSize: "1.25rem",
      fontWeight: 600,
      lineHeight: "1.75rem",
    },
    625: {
      fontSize: "0.625rem",
      fontWeight: 400,
      lineHeight: "0.875rem",
    },
    625100: {
      fontSize: "0.625rem",
      fontWeight: 400,
      lineHeight: "1rem",
    },
    8125: {
      fontSize: "0.8125rem",
      fontWeight: 400,
      lineHeight: "1rem",
    },
    8125500: {
      fontSize: "0.8125rem",
      fontWeight: 500,
      lineHeight: "1rem",
    },
    875: {
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: "1.25rem",
    },
    87500: {
      fontSize: "0.875rem",
      fontWeight: 500,
      lineHeight: "1.25rem",
    },
    875700: {
      fontSize: "0.875rem",
      fontWeight: 700,
      lineHeight: "1.25rem",
    },
    1500: {
      fontSize: "1rem",
      fontWeight: 500,
      lineHeight: "1.5rem",
    },
    1: {
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: "1.5rem",
    },
    1125: {
      fontSize: "1.125rem",
      fontWeight: 400,
      lineHeight: "1.75rem",
    },
    1125500: {
      fontSize: "1.125rem",
      fontWeight: 500,
      lineHeight: "1.75rem",
    },
    1125700: {
      fontSize: "1.125rem",
      fontWeight: 700,
      lineHeight: "1.75rem",
    },
    125: {
      fontSize: "1.25rem",
      fontWeight: 600,
      lineHeight: "1.75rem",
    },
    175: {
      fontSize: "1.75rem",
      fontWeight: 600,
      lineHeight: "2.25rem",
    },
    225: {
      fontSize: "2.25rem",
      fontWeight: 500,
      lineHeight: "2.75rem",
    },
  },
};

export default typography;
