import { NavItem } from "types/client/navigation-items";
import NavLink, { NavLinkProps } from "../navigation/NavLink";

type Props = {
  item: NavItem;
} & Partial<NavLinkProps>;

const NavMobile = ({ item, ...props }: Props) => {
  return (
    <NavLink
      item={item}
      isCollapsed={false}
      paddingX={4}
      paddingY={3}
      width="full"
      color={item.isActive ? "primary.light.4" : "neutral.light.7"}
      {...props}
    />
  );
};

export default NavMobile;
