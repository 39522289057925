import { Box, HStack, StackProps, Text } from "@chakra-ui/react";
import { memo } from "react";
import IconSvg from "./IconSvg";
import Tag from "./chakra/Tag";

type Props = {
  //
} & StackProps;

const SearchPitPromotion = ({ ...props }: Props) => {
  return (
    <HStack
      spacing={0}
      alignItems="center"
      userSelect="none"
      rowGap={1}
      maxWidth="full"
      overflow="hidden"
      {...props}
    >
      <HStack spacing={2} width="fit-content">
        <IconSvg flexShrink={0} boxSize={5} src="/images/logo/pit.svg" mr={2} />

        <Text
          as="span"
          whiteSpace="nowrap"
          textStyle="87500"
          flexShrink={0}
          mr={1}
        >
          Pit.Finance
        </Text>
      </HStack>

      <Box overflow="hidden">
        <Text
          textStyle="875"
          flexShrink={0}
          color="neutral.light.6"
          mr={1}
          isTruncated
        >
          - Maximizing your crypto yields
        </Text>
      </Box>

      <Tag
        flexShrink={0}
        paddingY="0.12rem"
        paddingX="0.375rem"
        colorScheme="gray"
      >
        Sponsored
      </Tag>
    </HStack>
  );
};

export default memo(SearchPitPromotion);
