import moment, { type Moment } from "moment";
import "moment-timezone";

export const DATE_FORMAT = "llll";

// Convert milliseconds to date
export const millisecondsToDays = (value: any) => {
  const ms = Number(value);
  if (Number.isNaN(ms)) return "";
  return ms / (1000 * 60 * 60 * 24);
};

// Update locale with your custom relativeTimeConfig
moment.updateLocale("en", {
  longDateFormat: {
    LTS: "h:mm:ss A",
    LT: "h:mm A",
    L: "MM/DD/YYYY",
    LL: "MMMM D, YYYY",
    LLL: "MMMM D, YYYY h:mm A",
    LLLL: "dddd, MMMM D, YYYY h:mm A",
    llll: "MMM DD YYYY HH:mm:ss A (Z UTC)", // Custom format
  },
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "%d secs",
    ss: "%d secs",
    m: "%d min",
    mm: "%d mins",
    h: "%d hr",
    hh: "%d hrs",
    dd: "%d days",
  },
});

moment.locale("en");

const originalFromNow = moment.prototype.fromNow as Moment["fromNow"];

moment.prototype.fromPast = function (
  this: moment.Moment,
  past: moment.Moment,
) {
  const duration = moment.duration(this.diff(past));
  const seconds = duration.asSeconds();
  const minutes = duration.asMinutes();
  const hours = duration.asHours();
  const days = duration.asDays();

  if (seconds < 60) {
    return seconds <= 1 ? "1 sec" : `${Math.floor(seconds)} secs`;
  } else if (minutes < 60) {
    return minutes === 1 ? "1 min" : `${Math.floor(minutes)} mins`;
  } else if (hours < 48) {
    return hours === 1 ? "1 hr" : `${Math.floor(hours)} hrs`;
  } else {
    return `${Math.floor(days)} days`;
  }
};

moment.prototype.fromNow = function (
  this: moment.Moment,
  ...args: Parameters<typeof originalFromNow>
) {
  if (this.locale() !== "en") return originalFromNow.apply(this, args);

  const now = moment();

  return now.fromPast(this);

  // const durationInSeconds = Math.floor(now.diff(this, "seconds", true));
  // if (durationInSeconds <= 1) return `${durationInSeconds} sec ago`;
  // if (durationInSeconds < 60) return `${durationInSeconds} secs ago`;

  // const durationInHours = now.diff(this, "hours", true);

  // if (durationInHours >= 24 && durationInHours < 48) {
  //   return `${Math.floor(durationInHours)} hrs ago`;
  // }
  // if (durationInHours >= 720) {
  //   const durationInDays = Math.floor(now.diff(this, "days", true));
  //   return `${durationInDays} days ago`;
  // }

  // return originalFromNow.apply(this, args);
};

/**
 * Get client side timezone.
 *
 * @returns {(+|-)HH:mm} - Where `HH` is 2 digits hours and `mm` 2 digits minutes.
 * @example
 * // From Indian/Reunion with UTC+4
 * // '+04:00'
 * getTimeZone()
 */
export const getTimeZone = () => {
  const timezoneOffset = new Date().getTimezoneOffset();
  const offset = Math.abs(timezoneOffset);
  const offsetOperator = timezoneOffset < 0 ? "+" : "-";
  const offsetHours = Math.floor(offset / 60)
    .toString()
    .padStart(2, "0");
  const offsetMinutes = Math.floor(offset % 60)
    .toString()
    .padStart(2, "0");

  return `${offsetOperator}${offsetHours}:${offsetMinutes}`;
};

export default moment;
