import type { As, BoxProps, TooltipProps } from "@chakra-ui/react";
import {
  Box,
  Center,
  chakra,
  Flex,
  forwardRef,
  Skeleton,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import _omit from "lodash/omit";
import React, { useRef } from "react";

import type { AddressParam } from "types/api/addressParams";

import { getIsContract } from "lib/getOSType";
import { route } from "nextjs-routes";

import * as EntityBase from "ui/shared/entities/base/components";

import OptimizationImage, {
  OptimizationImageProps,
} from "ui/shared/OptimizationImage";
import { getIconProps } from "../base/utils";
import AddressIdenticon from "./AddressIdenticon";

type LinkProps = EntityBase.LinkBaseProps & Pick<EntityProps, "address">;

const Link = chakra(
  forwardRef((props: LinkProps, ref) => {
    const defaultHref = route({
      pathname: (props.parentLink
        ? `${props.parentLink}/[hash]`
        : "/address/[hash]") as any,
      query: {
        ...props.query,
        hash: props.address.hash?.startsWith("factory/")
          ? props.address.hash.split("/")[1]
          : props.address.hash,
      },
    });

    return (
      <EntityBase.Link
        color={
          props.noLink || props.isNa || props.address.hash === "usei"
            ? "neutral.light.7"
            : "accent.blue"
        }
        cursor={props.isNa ? "inherit" : undefined}
        {...props}
        ref={ref}
        isNa={props.isNa}
        noLink={props.noLink || props.address.hash === "usei"}
        target={props.target ?? "_self"}
        href={
          props.isValidator
            ? route({
                pathname: "/validator/[hash]",
                query: { hash: props.address.hash },
              })
            : props.href
              ? props.href
              : defaultHref
        }
      >
        {props.children}
      </EntityBase.Link>
    );
  }),
);

type IconProps = Pick<
  EntityProps,
  "isLoading" | "iconSize" | "noIcon" | "isSafeAddress" | "isNa" | "isValidator"
> & {
  address: Required<Pick<EntityProps["address"], "hash">> &
    Partial<
      Pick<
        EntityProps["address"],
        | "image_url"
        | "is_contract"
        | "is_verified"
        | "implementation_name"
        | "name"
      >
    >;
  asProp?: As;
  optimizationImageProps?: Partial<OptimizationImageProps>;
} & BoxProps;

const Icon = (props: IconProps) => {
  const {
    noIcon,
    iconSize,
    isLoading,
    address,
    isSafeAddress,
    isNa,
    optimizationImageProps,
  } = props;
  if (noIcon || isNa) {
    return null;
  }

  const styles = {
    ...getIconProps(iconSize),
    marginRight: 0,
  };

  if (isLoading) {
    return <Skeleton {...styles} borderRadius="full" flexShrink={0} />;
  }

  if (address.is_contract) {
    if (isSafeAddress) {
      return (
        <EntityBase.Icon
          width={props.iconSize ?? "20px"}
          height={props.iconSize ?? "20px"}
          {...props}
          name="brands/safe"
        />
      );
    }

    if (address.is_verified) {
      return (
        <Tooltip label="Verified contract">
          <span>
            <EntityBase.Icon
              width={props.iconSize ?? "20px"}
              height={props.iconSize ?? "20px"}
              {...props}
              name="contract_verified_v2"
              color="secondary.02"
              borderRadius={0}
            />
          </span>
        </Tooltip>
      );
    }

    return (
      <Tooltip label="Contract">
        <span>
          <EntityBase.Icon
            width={props.iconSize ?? "20px"}
            height={props.iconSize ?? "20px"}
            {...props}
            name="contract"
            borderRadius={0}
          />
        </span>
      </Tooltip>
    );
  }

  const placeholder = (
    <AddressIdenticon
      size={iconSize === "lg" ? 30 : 20}
      hash={address.hash}
      width={props.iconSize ?? "20px"}
      height={props.iconSize ?? "20px"}
      {...(props as any)}
    />
  );
  return (
    <Tooltip
      label={address.implementation_name}
      isDisabled={!address.implementation_name}
    >
      <Center position="relative" marginRight={styles.marginRight} {...props}>
        {props.address.image_url && (
          <OptimizationImage
            hasWrapper
            src={props.address.image_url || undefined}
            alt={`${address.implementation_name || address.hash + "_avatar"}`}
            fallback={placeholder}
            borderRadius="full"
            {..._omit(optimizationImageProps, "wrapperProps")}
            wrapperProps={{
              objectFit: "cover",
              width: props.iconSize ?? "20px",
              height: props.iconSize ?? "20px",
              borderRadius: "full",
              ...props.optimizationImageProps?.wrapperProps,
            }}
          />
        )}
        {!props.address.image_url && placeholder}
      </Center>
    </Tooltip>
  );
};

type ContentProps = Omit<EntityBase.ContentBaseProps, "text"> &
  Pick<EntityProps, "address" | "tooltipProps" | "noToolTip">;

const Content = chakra(
  ({
    tooltipProps,
    noToolTip,
    ...props
  }: ContentProps & {
    isFullAddress?: boolean;
  }) => {
    if (props.address.name) {
      const label = (
        <VStack gap={0} py={1} color="inherit">
          <Box fontWeight={600} whiteSpace="pre-wrap" wordBreak="break-word">
            {props.address.name}
          </Box>
          <Box
            whiteSpace="pre-wrap"
            wordBreak="break-word"
            display={{ base: "none", md: "block" }}
          >
            {props.address.hash}
          </Box>
          <Box
            whiteSpace="pre-wrap"
            wordBreak="break-word"
            display={{ base: "block", md: "none" }}
          >
            {props.address.hash}
          </Box>
        </VStack>
      );

      return (
        <Tooltip label={noToolTip ? "" : label} {...tooltipProps}>
          <Skeleton
            isLoaded={!props.isLoading}
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            as="p"
            className="entity-content_skeleton"
            {...props}
          >
            {props.address.name}
          </Skeleton>
        </Tooltip>
      );
    }

    return (
      <EntityBase.Content
        {...props}
        color={props.linkColor}
        text={props.address.hash}
        noTooltip={noToolTip}
        tooltipProps={tooltipProps}
      />
    );
  },
);

type CopyProps = Omit<EntityBase.CopyBaseProps, "text"> &
  Pick<EntityProps, "address">;

const Copy = (props: CopyProps) => {
  return <EntityBase.Copy {...props} text={props.address.hash} />;
};

const Container = EntityBase.Container;

export interface EntityProps extends EntityBase.EntityBaseProps {
  address: Pick<
    AddressParam,
    "hash" | "name" | "is_contract" | "is_verified" | "implementation_name"
  > & { image_url?: string | null };
  showAddress?: boolean;
  isSafeAddress?: boolean;
  isFullAddress?: boolean;
  parentLink?: string;
  linkProps?: Partial<LinkProps>;
  iconProps?: Partial<IconProps>;
  contentProps?: Partial<ContentProps>;
  tooltipProps?: Partial<TooltipProps> | null;
  noToolTip?: boolean;
  copyProps?: CopyProps;
  subAddressProps?: Partial<ContentProps>;
}

const AddressEntry = ({
  iconProps,
  copyProps,
  subAddressProps,
  ...props
}: EntityProps) => {
  const isNa = !props.address.hash || props.address.hash === "N/A";

  const newProps = {
    ...props,
    isNa: props.isNa || isNa,
    address: {
      ...props.address,
      is_contract: getIsContract(props.address as any),
    },
  };
  const linkProps = _omit(newProps, ["className"]);
  const partsProps = _omit(newProps, ["className", "onClick", "isFullAddress"]);

  const ref = useRef<HTMLDivElement | null>(null);
  const contentBoxRef = useRef<HTMLDivElement | null>(null);

  if (!props.address?.hash) return <></>;
  return (
    <Container className={props.className} ref={ref} gap={1}>
      {!isNa && <Icon {...partsProps} {...iconProps} />}
      {!props.showAddress && (
        <Link ref={contentBoxRef} {...linkProps} {...props.linkProps}>
          <Content
            color="inherit"
            entityRef={ref}
            contentBoxRef={contentBoxRef}
            isFullAddress={props.isFullAddress}
            {...partsProps}
            {...props.contentProps}
            {...props.tooltipProps}
          />
        </Link>
      )}
      {props.showAddress && (
        <Flex
          flexDirection="column"
          gap="0.125rem"
          ref={contentBoxRef}
          pl="2px"
        >
          <Link {...linkProps} {...props.linkProps}>
            <Content
              color="inherit"
              isFullAddress={props.isFullAddress}
              entityRef={ref}
              contentBoxRef={contentBoxRef}
              {...partsProps}
              {...props.contentProps}
              {...props.tooltipProps}
            />
          </Link>

          <Flex display="flex" alignItems="center" gap={1}>
            <Content
              headLength={4}
              tailLength={4}
              entityRef={ref}
              truncation="constant"
              color="neutral.light.6"
              contentBoxRef={contentBoxRef}
              isFullAddress={props.isFullAddress}
              {...partsProps}
              {...props.contentProps}
              {...props.tooltipProps}
              address={{
                ...props.address,
                name: "",
              }}
              {...subAddressProps}
            />
            <Copy {...partsProps} {...copyProps} />
          </Flex>
        </Flex>
      )}
      {!props.showAddress && <Copy {...partsProps} {...copyProps} />}
    </Container>
  );
};

export default React.memo(chakra(AddressEntry));

export { Container, Content, Copy, Icon, Link };
