import { useCallback } from "react";

import { useLogto } from "@logto/react";
import config from "configs/app";
import * as cookies from "lib/cookies";

import ProfileNavButton from "./ProfileNavButton";

const feature = config.features.account;

type Props = {
  isLoading?: boolean;
  onClose?: () => void;
};

const ProfileSignOutButton = ({ isLoading, onClose }: Props) => {
  const { signOut } = useLogto();

  const handleSingOutClick = useCallback(() => {
    cookies.deleteKey(cookies.NAMES.API_TOKEN);
    signOut(config.app.authWorkspace.redirectUrl);
  }, [signOut]);

  if (!feature.isEnabled) {
    return null;
  }
  return (
    <ProfileNavButton
      title="Sign out"
      icon="sign-out"
      height="3rem"
      padding={3}
      gap={3}
      variant="unstyled"
      color="accent.red"
      borderRadius="0.5rem"
      display="flex"
      alignItems="center"
      _hover={{
        backgroundColor: "primary.light.1",
      }}
      isLoading={isLoading}
      onClick={() => {
        handleSingOutClick();
        onClose?.();
      }}
    ></ProfileNavButton>
  );
};

export default ProfileSignOutButton;
