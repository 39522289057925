import { MenuItem, MenuItemProps } from "@chakra-ui/react";
import { NavItem } from "types/client/navigation-items";
import { default as NavLink } from "../navigation/NavLink";

type Props = {
  item: NavItem;
} & Partial<MenuItemProps>;

const NavDesktop = ({ item, ...props }: Props) => {
  return (
    <MenuItem
      as={NavLink}
      item={item}
      isCollapsed={false}
      height="3rem"
      color={item.isActive ? "primary.light.4" : "neutral.light.7"}
      {...props}
    ></MenuItem>
  );
};

export default NavDesktop;
